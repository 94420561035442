<template>
  <div style="padding-bottom: 80px;">
    <!-- <you-lost-access-sidebar
      :show-open-you-lost-access-sidebar.sync="showOpenYouLostAccessSidebar"
      :url="idsite.domain"
    /> -->
    <sidebar-ownership
      :idsite="idsite"
      :show-open.sync="showOpen"
      :type-ownership.sync="idsite.lost"
    />
    <change-project
      :idsite="idsite"
      :show-open-change-project.sync="showOpenChangeProject"
    />
    <archive-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />
    <activate-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenActiveSite"
    />
    <info-license
      :idsite="idsite"
      :user-data.sync="userData"
      :show-open-info-license.sync="showOpenInfoLicense"
    />
    <!-- <members-project
      :idsite="idsite"
      :show-open-members-project.sync="showOpenMembersProject"
    /> -->
    <b-row class="align-items-center mb-4" v-if="rows && rows.length >= 1">
      <b-col md="6">
        <b-button
          variant="info"
          class="mr-1 cursor-pointer"
          :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? 'height: 44px !important; cursor: pointer !important; padding-top: 14px;' : 'height: 44px !important; cursor: not-allowed !important; padding-top: 14px;'"
          :to="{ name: 'edit-project', params: {id: $route.params.id} }"
          :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : true"
        >
          <feather-icon
            icon="Edit2Icon"
            class="mr-50"
          />
          <span class="">Edit project</span>
        </b-button>
        <b-button
          variant="info"
          class="cursor-pointer"
          :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? 'height: 44px !important; cursor: pointer !important; padding-top: 14px;' : 'height: 44px !important; cursor: pointer !important; padding-top: 14px;'"
          :to="{ name: 'new-site', params: {idPro: $route.params.id, objPr: objPr} }"
          :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : false"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="">New Site</span>
        </b-button>
      </b-col>
      <b-col md="6">
        <div class="d-flex float-right">
          <b-button
            variant="secondary"
            style="height: 44px !important; background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important;"
            :to="{ name: 'archive-sites' }"
            class="mr-50"
            v-if="existArchivedSites"
          >
            <feather-icon
              icon="ArchiveIcon"
              class="mr-50"
            />
            <span class="align-middle">Archived sites</span>
          </b-button>

          <!-- <vue-autosuggest
            v-model="searchSite"
            :suggestions="suggestionsSites"
            :input-props="inputProps"
            :section-configs="sectionConfigs"
            :render-suggestion="renderSuggestion"
            @input="fetchResults"
            style="width: 325px; height: 44px; border: 1px solid transparent; border-radius: 22px;"
            class="autosuggest-sites"
          >
          </vue-autosuggest> -->
        </div>
      </b-col>
    </b-row>

    <b-overlay
      :show="loadingSites"
      rounded="xl"
      no-fade
    >
      <span
        class="mb-25"
        style="font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; margin-top: 0px;"
      >
        <div
          :style="`width: 12px; height: 12px; border-radius: 4px; background: ${rows.length >= 1 ? rows[0].color : '#ddd'}; margin-top: 6px;`"
          class="mr-50"
        />
        {{ rows.length >= 1 ? rows[0].label : '' }}
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-light"
          class="btn-icon float-right py-25"
          :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin') ? 'cursor: pointer !important;' : 'cursor: not-allowed !important;'"
          :to="{ name: 'edit-project', params: {id: $route.params.id} }"
          v-if="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin')"
        >
          <feather-icon icon="Edit2Icon" />
        </b-button>
      </span>
      <span class="ml-2" style="color: #878a87; font-size: 17px !important;">
        {{ rows.length >= 1 && rows[0].children.length ? rows[0].children.length : 0 }} sites
      </span>

      <div class="top-scroll-horizontal" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2" style="height: 20px;"></div></div>
      <vue-good-table
        :columns="isDevSite ? columnsDev : columns"
        :rows="rows"
        :rtl="direction"
        id="tab-err-sc1"
        style-class="vgt-table condensed bordered vgt-table bordered striped table-wproject-sites"
        :group-options="{
          enabled: true,
          headerPosition: 'bottom',
          collapsable: false,
        }"
        :sort-options="{
          enabled: true,
        }"
        @on-cell-click="onRowSelected"
        class="table-show-project"
      >
        <div slot="emptystate">
          No sites
        </div>
        <template
          slot="table-header-row"
          slot-scope="props"
          class="p-0"
        >
          <div
            :style="`background: ${props.row.color};`"
            class="mr-50"
          />
          <span v-if="rows.length >= 1 && rows[0].children.length === 0">
            No sites
          </span>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'imgSite'"
            style="padding-top: 40px !important;"
          >
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                  id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rows[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class="w-100"
              style="height: 100% !important; display: block;"
            >
              <b-img-lazy
                :src="props.formattedRow['imgSite']"
                onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b';"
                width="120px"
                height="70px"
                class="my-50"
                loading="lazy"
                style="padding-left: 0px !important;"
                :alt="'img not found'"
              />
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'name'">
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                  id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rows[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class="w-100"
              style="height: 100% !important; display: block;"
            >
              <div :style="props.row.crawl && props.row.crawl.estatus === 'running' ? 'top: -12px; position: relative;' : ''">
                <span class="" style="font-size: 15px; color: #181E38;">{{ props.row.name }}</span>
                <div style="width: 200px; position: absolute;" v-if="props.row.crawl && props.row.crawl.estatus === 'running'">
                  <b-progress
                    :value="props.row.crawl.requests ? props.row.crawl.requests : 0"
                    :max="props.row.crawl.availableURL ? props.row.crawl.availableURL : 1"
                    class="progress-bar-info mt-25"
                    variant="info"
                    style="height: 5px;"
                  />
                  <span class="" style="color: #A7AAB9; font-size: 14px;">Crawling {{props.row.crawl.requests ? props.row.crawl.requests : 0}} of {{props.row.crawl.availableURL ? props.row.crawl.availableURL : 1}} pages</span>
                </div>
              </div>
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'domainFix'">
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                  id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rows[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class="w-100"
              style="height: 100% !important; display: block;"
            >
              <span class="" :style="props.row.crawl && props.row.crawl.estatus === 'running' ? 'top: -12px; position: relative; font-size: 15px; color: #A7AAB9;' : 'font-size: 15px; color: #A7AAB9;'">{{ props.row['domainFix'] }}</span>
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'currentErrors'">
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                  id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rows[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class="w-100"
              style="height: 100% !important; display: block;"
            >
              <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />
              <span
                class=""
                style="font-size: 15px; color: #FC7D7D;"
                v-else
              >
                {{ props.formattedRow['currentErrors'] }}
                <b-avatar
                  variant="light-danger"
                  size="12px"
                  style="margin-bottom: 7px; background: rgba(234, 84, 85, 0.12) !important;"
                >
                  <feather-icon icon="ChevronDownIcon" />
                </b-avatar>
              </span>
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'fixedIssues'"
            style="text-align: center;"
          >
            <b-link 
              :to="{
                name: 'view-errors',
                params: {
                  id: props.row.id, imgSite: props.row.imgSite, name: props.row.name, url: props.row.domain, project: props.row.projectid, own: rows[0].permission === 'owner' ? 'me' : 'other'
                },
              }"
              class="w-100"
              style="height: 100% !important; display: block;"
            >
              <span
                class=""
                style="font-size: 15px; color: #53D991;"
              >
                {{ props.formattedRow['fixedIssues'] }}
                <b-avatar
                  variant="light-success"
                  size="12px"
                  style="margin-bottom: 7px; background: rgba(40, 199, 111, 0.12) !important;"
                >
                  <feather-icon icon="ChevronUpIcon" />
                </b-avatar>
              </span>
            </b-link>
          </span>
          <span v-else-if="props.column.field == 'key'">
            <span
              class=""
              style="font-size: 15px; color: #656B85;"
            >••••••••{{ props.formattedRow['key'].length === 21 ? props.formattedRow['key'].slice(19) : props.formattedRow['key'].slice(20) }}</span>
            <b-button
              v-clipboard:copy="props.formattedRow['key']"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              style="color: #A7AAB9;"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </span>
          <span v-else-if="props.column.field == 'alerts'">
            <span
              class=""
              style="font-size: 15px; color: #7838ff;"
            >{{ props.formattedRow['alerts'] }}</span>
          </span>
          <span v-else-if="props.column.field == 'membership'">
            <span
              v-if="props.formattedRow['membership'] === 'Free' || props.formattedRow['membership'] === 'free' || props.formattedRow['membership'] === 'Free license'"
              class="w-100 pb-50 d-block cursor-initial"
              style="margin-left: 3.1rem !important; font-size: 1rem; padding-left: 17px;"
            >Free</span>
            <b-dropdown
              v-if="props.formattedRow['membership'] !== 'Free' && props.formattedRow['membership'] !== 'Free license'"
              :text="props.formattedRow['membership']"
              :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
              variant="outline-primary"
              class="style-chooser ml-2 mb-25"
              :class="props.formattedRow['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
              :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : true"
            >
              <template #button-content>
                <b-img-lazy
                  v-if="props.formattedRow['membership'] !== 'Free'"
                  fluid
                  :src="proIcon"
                  alt="pro page img"
                  width="17"
                  height="16"
                  style="margin-bottom: 7px;"
                  class="mr-0"
                  loading="lazy"
                />
                <!-- {{ props.formattedRow['membership'] }} -->
                Licensed
              </template>
              <b-dropdown-item style="width: 180px;"
                @click="logIdLicense(props.row)"
              >
                <span style="font-size: 15px; color: #656B85;">
                  <feather-icon
                    icon="ArrowUpIcon"
                    class="mt-0 pt-0"
                    size="12"
                  />
                  Upgrade
                </span><br>
                <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 10px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
              </b-dropdown-item>
              <b-dropdown-item style="width: 180px;" @click="logIdLicense(props.row)">
                <span style="font-size: 15px; color: #656B85;">
                  <feather-icon
                    icon="ArrowDownIcon"
                    class="mt-0 pt-0"
                    size="12"
                  />
                  Downgrade
                </span><br>
                <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 10px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              variant="outline-secondary"
              block
              class="ml-3 px-0 py-25"
              :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? 'cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'cursor: not-allowed !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
              @click="logIdLicense(props.row)"
              :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : true"
              pill
            >
              Upgrade
            </b-button>
          </span>
          <span v-else-if="props.column.field == 'ownedBy'">
            <div v-if="userData.uid !== props.formattedRow['ownedBy'].uid || !props.formattedRow['ownedBy'] || !props.formattedRow['ownedBy'].uid">
              <div class="d-flex mb-50" v-if="props.row.own === 'me'">
                <b-avatar
                  size="27"
                  :src="props.formattedRow['ownedBy'].foto ? props.formattedRow['ownedBy'].foto : ''"
                  variant="light-dark"
                  class="badge-minimal"
                  alt="img user"
                  :text="avatarText(!props.formattedRow['ownedBy'].foto ? props.formattedRow['ownedBy'].name : '')"
                  style="background: #ddd !important;"
                  v-if="userData.uid === props.formattedRow['ownedBy'].uid"
                >
                  <span v-if="!props.formattedRow['ownedBy'].foto && !props.formattedRow['ownedBy'].name">{{ avatarText(props.formattedRow['ownedBy'].name) }}</span>
                </b-avatar>

                <div class="ml-25 mt-50">
                  <p class="mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; cursor: initial !important;" v-if="userData.uid === props.formattedRow['ownedBy'].uid">
                    {{ props.formattedRow['ownedBy'].name }}
                  </p>

                  <b-dropdown
                    :text="props.formattedRow['membership']"
                    variant="outline-secondary"
                    class="style-chooser cursor-pointer"
                    :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit' || rows[0].permission === 'view') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                    :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit' || rows[0].permission === 'view') ? false : true"
                    v-else
                  >
                    <template #button-content>
                      <b-avatar
                        size="27"
                        :src="props.formattedRow['ownedBy'].foto ? props.formattedRow['ownedBy'].foto : ''"
                        variant="light-dark"
                        class="badge-minimal"
                        alt="img user"
                        :text="avatarText(props.formattedRow['ownedBy'].name ? (props.formattedRow['ownedBy'].name.includes(' ') ? props.formattedRow['ownedBy'].name : props.formattedRow['ownedBy'].name + ' ' + props.formattedRow['ownedBy'].name.charAt(1)) : props.formattedRow['ownedBy'].email ? props.formattedRow['ownedBy'].email + ' ' + props.formattedRow['ownedBy'].email.charAt(1): 'AN')"
                        style="background: #ddd !important;"
                      >
                        <!-- <feather-icon
                          v-if="!props.formattedRow['ownedBy'].foto && !props.formattedRow['ownedBy'].name"
                          icon="UserIcon"
                          size="22"
                        /> -->
                        <span v-if="!props.formattedRow['ownedBy'].foto && !props.formattedRow['ownedBy'].name && !props.formattedRow['ownedBy'].email">{{ avatarText(props.formattedRow['ownedBy'].name) }}</span>
                      </b-avatar>
                      <span class="ml-25 mt-50 mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;">
                        {{ props.formattedRow['ownedBy'].name ? props.formattedRow['ownedBy'].name : props.formattedRow['ownedBy'].email }}
                      </span>
                    </template>
                    <b-dropdown-item
                      :style="rows.length >= 1 && (rows[0].permission === 'view') ? 'cursor: not-allowed; width: 180px; text-align: center;' : 'width: 180px; text-align: center;'"
                      :class="rows.length >= 1 && (rows[0].permission === 'view') ? 'cursor-initial' : 'cursor-pointer'"
                      @click="logIdSite(props.row)"
                      :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : true"
                    >
                      <span style="font-size: 15px; color: #656B85; text-align: center;">
                        Claim
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <span style="color: #FC7D7D; font-size: 12px;" class="d-block" v-if="props.row.lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row"><feather-icon icon="HelpCircleIcon" style="color: #FC7D7D; position: relative; top: -2px;" /> disconnected</span>
              </div>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :style="rows.length >= 1 && (rows[0].permission === 'view') ? 'pointer-events: initial !important; cursor: not-allowed !important; background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;' : 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                size="sm"
                class=""
                @click="logIdSite(props.row)"
                :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : true"
              >
              Claim
              </b-button>
              <span style="color: #FC7D7D; font-size: 12px;" class="d-block" v-if="props.row.lost" @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row"><feather-icon icon="HelpCircleIcon" style="color: #FC7D7D; position: relative; top: -2px;" /> disconnected</span>
            </div>
            <div v-else>
              <div class="d-flex">
                <b-avatar
                  size="27"
                  :src="props.formattedRow['ownedBy'].foto ? props.formattedRow['ownedBy'].foto : ''"
                  variant="light-dark"
                  class="badge-minimal"
                  alt="img user"
                  style="background: #ddd !important;"
                  :text="avatarText(props.formattedRow['ownedBy'].name ? (props.formattedRow['ownedBy'].name.includes(' ') ? props.formattedRow['ownedBy'].name : props.formattedRow['ownedBy'].name + ' ' + props.formattedRow['ownedBy'].name.charAt(1)) : props.formattedRow['ownedBy'].email ? props.formattedRow['ownedBy'].email + ' ' + props.formattedRow['ownedBy'].email.charAt(1): 'AN')"
                >
                  <!-- <feather-icon
                    v-if="!props.formattedRow['ownedBy'].foto && !props.formattedRow['ownedBy'].name"
                    icon="UserIcon"
                    size="22"
                  /> -->
                  <span v-if="!props.formattedRow['ownedBy'].foto && !props.formattedRow['ownedBy'].name && !props.formattedRow['ownedBy'].email">{{ avatarText(props.formattedRow['ownedBy'].name) }}</span>
                </b-avatar>

                <div :class="userData.uid === props.formattedRow['ownedBy'].uid ? 'ml-25 mt-25' : 'ml-25 mt-50'">
                  <p
                    v-if="userData.uid === props.formattedRow['ownedBy'].uid"
                    class="mb-0 "
                    style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                  >
                    Me
                  </p>
                  <p
                    v-else
                    class="mb-0 "
                    style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                  >
                    {{ props.formattedRow['ownedBy'].name ? props.formattedRow['ownedBy'].name : props.formattedRow['ownedBy'].email }}
                  </p>
                </div>
              </div>
              <span
                v-if="props.row.lost"
                style="color: #FC7D7D; font-size: 12px;"
                class=""
                @click.prevent="showOpenYouLostAccessSidebar = true, idsite = props.row"
              ><feather-icon
                icon="HelpCircleIcon"
                style="color: #FC7D7D; position: relative; top: -2px;"
              /> disconnected</span>
            </div>
          </span>
          <span v-else-if="props.column.field == 'action'">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              dropleft
              no-caret
              variant="flat-dark"
              class="btn-icon dropdown-sites rounded-circle"
            >
              <template #button-content class="pt-0">
                <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
              </template>
              <b-dropdown-item
                link-class="d-flex align-items-center  item-dropdown-action-sites"
                @click="getError(props.row)"
                :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit' || rows[0].permission === 'view') ? false : true"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                  style="position: relative !important; left: -7px !important;"
                />
                Fix
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center  item-dropdown-action-sites"
                :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                @click="logIdChangeProject(props.row)"
                :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin') ? false : true"
              >
                <feather-icon
                  icon="RepeatIcon"
                  class="mr-50"
                  style="position: relative !important; left: -7px !important;"
                />
                Change Project
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center  item-dropdown-action-sites"
                :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                @click="logIdArchive(props.row)"
                :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin') ? false : true"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-50"
                  style="position: relative !important; left: -7px !important;"
                />
                Archive Site
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center  item-dropdown-action-sites"
                :style="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? 'cursor: initial !important;' : 'cursor: not-allowed !important;'"
                :to="{ name: 'edit-site', params: { id: props.row.id, project: $route.params.id } }"
                :disabled="rows.length >= 1 && (rows[0].permission === 'owner' || rows[0].permission === 'admin' || rows[0].permission === 'edit') ? false : true"
              >
                <feather-icon
                  icon="SettingsIcon"
                  class="mr-50"
                  style="position: relative !important; left: -7px !important;"
                />
                Edit Site
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </vue-good-table>
      <div class="text-center text-primary my-2" v-if="loadingInfiniteScroll">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-1">Loading sites...</strong>
      </div>

      <div v-if="rows.length && rows[0].children.length === 0" style="margin-top: 170px; text-align: center;">
        <b-button
          variant="info"
          class=""
          style="height: 44px !important;"
          :to="{ name: 'new-site', params: {origin: 'my-sites'} }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span
            class="align-middle"
          >Add your site</span>
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BImgLazy, BButton,
  BRow, BCol, BProgress, BSpinner, BOverlay, BCardText, BCard, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import YouLostAccessSidebar from '@/views/extensions/acl/table/sidebar/YouLostAccessSidebar.vue'
import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
import ChangeProject from '@/views/extensions/acl/table/sidebar/ChangeProject.vue'
import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
import ActivateSite from '@/views/extensions/acl/table/sidebar/ActivateSite.vue'
import MembersProject from '@/views/extensions/acl/table/sidebar/MembersProject.vue'
import InfoLicense from '@/views/extensions/acl/table/sidebar/InfoLicense.vue'
import 'vue-good-table/dist/vue-good-table.css'
import moment from 'moment'
import store from '@/store/index'
import Urlbox from 'urlbox'
import { db, auth, storage } from '@/firebase'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BImgLazy,
    BButton,
    BRow,
    BCol,
    BProgress,
    BSpinner,
    BOverlay,
    BCardText,
    BCard,
    BLink,
    vSelect,
    YouLostAccessSidebar,
    SidebarOwnership,
    ChangeProject,
    ArchiveSite,
    ActivateSite,
    MembersProject,
    InfoLicense,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: '',
      selectedPlan: 'Basic',
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      optionsPlan: [
        'Free',
        'Basic',
        'Pro',
        'Custom license',
      ],
      loaderSearch: true,
      existArchivedSites: false,

      // table
      columns: [
        {
          label: '',
          field: 'imgSite',
          sortable: false,
          thClass: 'thTableSites',
          tdClass: 'tdTableSitesImg',
        },
        {
          label: 'Site Name',
          field: 'name',
          thClass: 'thTableSitesNameShow',
          tdClass: 'tdTableSitesNameShow',
        },
        {
          label: 'URL',
          field: 'domainFix',
          thClass: 'thTableSitesDomain',
          tdClass: 'tdTableSitesDomain',
        },
        {
          label: 'Current Errors',
          field: 'currentErrors',
          type: 'number',
          // sortable: false,
          thClass: 'thTableSitesViewListErrors',
          tdClass: 'tdTableSitesViewListErrors',
        },
        // {
        //   label: 'Fixed Issues',
        //   field: 'fixedIssues',
        //   // sortable: false,
        //   thClass: 'thTableSitesViewList',
        //   tdClass: 'tdTableSitesViewList',
        // },
        // {
        //   label: 'Key',
        //   field: 'key',
        //   sortable: false,
        //   thClass: 'thTableSitesViewListKey',
        //   tdClass: 'tdTableSitesKeyViewListKey',
        // },
        // {
        //     label: 'Alerts',
        //     field: 'alerts',
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        {
          label: 'Membership',
          field: 'membership',
          sortable: false,
          thClass: 'thTableSitesViewListMembership',
          tdClass: 'tdTableSitesViewListMembership',
        },
        {
          label: 'Owned by',
          field: 'ownedBy',
          sortable: false,
          thClass: 'thTableSitesOwned',
          tdClass: 'tdTableSitesOwned',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'thTableSitesAction',
          tdClass: 'tdTableSitesAction',
        },
      ],
      columnsDev: [
        {
          label: '',
          field: 'imgSite',
          sortable: false,
          thClass: 'thTableSites',
          tdClass: 'tdTableSitesImg',
        },
        {
          label: 'Site Name',
          field: 'name',
          thClass: 'thTableSitesNameShow',
          tdClass: 'tdTableSitesNameShow',
        },
        {
          label: 'URL',
          field: 'domainFix',
          thClass: 'thTableSitesDomain',
          tdClass: 'tdTableSitesDomain',
        },
        {
          label: 'Current Errors',
          field: 'currentErrors',
          type: 'number',
          // sortable: false,
          thClass: 'thTableSitesViewListErrors',
          tdClass: 'tdTableSitesViewListErrors',
        },
        // {
        //   label: 'Fixed Issues',
        //   field: 'fixedIssues',
        //   // sortable: false,
        //   thClass: 'thTableSitesViewList',
        //   tdClass: 'tdTableSitesViewList',
        // },
        // {
        //   label: 'Key',
        //   field: 'key',
        //   sortable: false,
        //   thClass: 'thTableSitesViewListKey',
        //   tdClass: 'tdTableSitesKeyViewListKey',
        // },
        // {
        //     label: 'Alerts',
        //     field: 'alerts',
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        {
          label: 'Membership',
          field: 'membership',
          sortable: false,
          thClass: 'thTableSitesViewListMembership',
          tdClass: 'tdTableSitesViewListMembership',
        },
        // {
        //   label: 'Owned by',
        //   field: 'ownedBy',
        //   sortable: false,
        //   thClass: 'thTableSitesOwned',
        //   tdClass: 'tdTableSitesOwned',
        // },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'thTableSitesAction',
          tdClass: 'tdTableSitesAction',
        },
      ],
      rows: [
        // {
        //     mode: 'span',
        //     html: false,
        //     label: 'Mammals Total', // this is the label that'll be used for the header
        //     diet: undefined,
        //     count: '', // total count will be displayed here
        //     children: [
        //         { name: 'Elephant', diet: 'herbivore', count: 5 },
        //         { name: 'Cat', diet: 'carnivore', count: 28 }
        //     ]
        // },
      ],
      dir: false,
      searchTerm: '',
      pageLength: 5,
      objPr: '',
      idsite: '',
      projectsData: '',
      invitationsProjectData: '',

      showOpen: false,
      showOpenChangeProject: false,
      showOpenArchiveSite: false,
      showOpenActiveSite: false,
      showOpenMembersProject: false,
      showOpenYouLostAccessSidebar: false,
      showOpenInfoLicense: false,
      loadErrors: true,
      initDate: 0,
      initDate2: 0,
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],

      // infinite scroll
      latestDocClient: null,
      loadingInfiniteScroll: false,
      loadingSites: false,
      totalSites: 0,

      // autosuggest
      datasuggest: {
        data: [{
          sites: [],
        }],
      },
      searchSite: '',
      suggestionsSites: [],
      selected: null,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search",
      },
      sectionConfigs: {
        sites: {
          limit: 60,
          // label: 'Sites',
          onSelected: selected => {
            // console.log(selected.item)
            this.selected = selected.item
          },
        },
        default: {
          limit: 60,
          onSelected: selected => {
            // console.log(selected)
            this.selected = selected.item
          }
        },
      },
      limit: 60,

      // new vars migration firebase
      SiteID: '',
      showOpenComingSoon: false,
      isDevSite: false,
    }
  },
  setup() {
    return {
        avatarText,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    searchSite(val) {
      setTimeout(() => {
        const collection = document.getElementsByClassName('vgt-text-disabled')
        if (collection[0] && val) {
          collection[0].innerHTML = 'No results found'
        }
      }, 1000)
    },
    selected(val) {
      // console.log(val)
      this.getSite(val)
    },
  },
  created() {
    // horizontal scroll
    this.$nextTick(() => {
      $(".top-scroll-horizontal").scroll(() => {
        $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal").scrollLeft());
      });

      $(".vgt-responsive").scroll(() => {
        $(".top-scroll-horizontal").scrollLeft($(".vgt-responsive").scrollLeft());
      });
    });

    localStorage.removeItem('configRunLicense')
    this.existArchivedSites = false
    this.SiteID = ''
    this.loadErrors = true
    this.initDate = 0
    this.initDate2 = 0
    this.rows = []
    this.showOpenInfoLicense = false
    this.loadingSites = true

    setTimeout(() => {
      this.loadingSites = false
    }, 1500)

    this.showOpenComingSoon = false
    this.isDevSite = false
    var urlWindow = window.location.href
    var urlWindowTransform = new URL(urlWindow)
    var urlWindowDomain = urlWindowTransform.hostname
    // console.log(urlWindowDomain)
    if(urlWindowDomain === 'app.seocloud.ai' || urlWindowDomain === 'localhost') {
      // this.isDevSite = true
    }
    // this.$route.params.title = 'this.user.username'
    // this.$route.params.pageTitle = 'this.user.username'
    // console.log(this.$route)
    const user = auth.currentUser
    if(user) {
      // this.userData = {
      //   uid: user.uid
      // }
      this.loadErrors = false

      db.collection('Users').doc(user.uid)
      .onSnapshot((userDoc) => {
        this.userData = {
          uid: user.uid,
          email: userDoc.data().email
        }
        // this.userData.email = userDoc.data().email
        var teamsVar = userDoc.data().teams && userDoc.data().teams.length ? userDoc.data().teams : []
        if(teamsVar.includes(this.$route.params.id)) {
          db.collection('Projects').doc(this.$route.params.id).get()
          .then(projectDoc => {
            this.objPr = {
              title: projectDoc.data().name,
              icon: projectDoc.data().color,
              uid: projectDoc.id,
            }
            window.document.title = 'Project: ' + projectDoc.data().name + ' - SEOcloud'

            this.rows = []

            this.rows.push({
              mode: 'span',
              html: false,
              label: projectDoc.data().name,
              color: projectDoc.data().color,
              id: projectDoc.data().id,
              count: 6,
              children: [],
              permission: 'owner',
            })

            // db.collection('Projects').doc(this.$route.params.id).collection('sites').where('status', '!=', 'archive').orderBy('status').orderBy('n', 'asc')
            db.collection('Projects').doc(this.$route.params.id).collection('sites').orderBy('n', 'asc')
            .onSnapshot(projectSites => {
              this.rows[0].children = []
              this.existArchivedSites = false
              projectSites.forEach(siteDoc => {
                if(siteDoc.data().status === 'archive') {
                  this.existArchivedSites = true
                }

                // console.log(siteDoc.data().status)
                if(siteDoc.data().status && siteDoc.data().status !== 'archive') {
                  var filterUserMember = projectDoc.data().teamMembers.filter(ele => ele.id === user.uid)
                  var memberPerm = ''
                  if(filterUserMember.length >= 1) {
                    memberPerm = filterUserMember[0].role
                  }

                  // if(!siteDoc.data().thumbnail) {
                  //   // console.log(siteDoc.id)
                    
                  //   // Plugin your API key and secret
                  //   const urlbox = Urlbox('iWVAo1U5wUbLhzWJ', 'c3e32765daba406cb27d1ad22ed93b6d');
                    
                  //   // Set your options
                  //   const options = {
                  //     url: siteDoc.data().homepage,
                  //     // thumb_width: 1440,
                  //     width: 1440,
                  //     height: 840,
                  //     format: "jpg",
                  //     quality: 70,
                  //     delay: 500
                  //   };
                    
                  //   const urlImgUrl = urlbox.buildUrl(options)

                  //   var obj = {
                  //     url: urlImgUrl
                  //   }
                  //   var config = {
                  //     method: 'POST',
                  //     url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
                  //     headers: {
                  //       'Content-Type': 'application/json',
                  //     },
                  //     data: JSON.stringify(obj),
                  //   }

                  //   this.axios(config)
                  //   .then(async response => {
                  //     const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}siteImg`)
                  //     const upl = await refImg.putString(response.data, 'data_url')
                  //     const urlDescarga = await refImg.getDownloadURL()
                  //     // this.imgSite = urlDescarga

                  //     // update thumbnail
                  //     db.collection('Sites').doc(siteDoc.id).update({
                  //       thumbnail: urlDescarga
                  //     })

                  //     db.collection('Sites_cache').doc(siteDoc.id).update({
                  //       thumbnail: urlDescarga
                  //     })

                  //     db.collection('Projects').doc(this.$route.params.id).collection('sites').doc(siteDoc.id).update({
                  //       thumbnail: urlDescarga
                  //     })

                  //     db.collection('Projects').doc(this.$route.params.id).collection('sites').doc(siteDoc.id).get()
                  //     .then(itemP => {
                  //       if(itemP.exists) {
                  //         if(itemP.data().homepage) {
                  //           var filterP = this.rows[0].children.filter(ele => ele.domainFix === itemP.data().homepage)
                  //           filterP.forEach(ele => {
                  //             ele.imgSite = urlDescarga
                  //           })
                  //         }
                  //       }
                  //     })
                  //   })
                  //   .catch(error => {
                  //     console.log(error)
                  //   })
                  // }
                  // console.log(siteDoc.data().plan, siteDoc.data().paidRecurrency);

                  this.rows[0].children.push({
                    imgSite: siteDoc.data().thumbnail ? siteDoc.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    name: siteDoc.data().name ? siteDoc.data().name : siteDoc.data().homepage,
                    domainFix: siteDoc.data().homepage,
                    domain: siteDoc.data().homepage ? siteDoc.data().homepage : siteDoc.data().WebsiteURL,
                    currentErrors: siteDoc.data().totalErrors,
                    fixedIssues: '79',
                    key: siteDoc.id,
                    alerts: '3',
                    membership: `${siteDoc.data().plan ? siteDoc.data().plan : 'Free'} license`,
                    ownedBy: siteDoc.data().owner && siteDoc.data().owner.uid ? siteDoc.data().owner : '',
                    label: projectDoc.data().name,
                    color: projectDoc.data().color,
                    id: siteDoc.id,
                    projectid: projectDoc.data().id,
                    owner: siteDoc.data().owner && siteDoc.data().owner.uid === user.uid ? 'me' : memberPerm ? memberPerm : 'me',
                    member: true,
                    permission: 'owner',
                    own: siteDoc.data().owner && siteDoc.data().owner.uid === user.uid ? 'me' : 'other',
                    crawl: '',
                    lost: false,

                    nextDue: siteDoc.data().due_end ? moment(siteDoc.data().due_end*1000).format('MM/DD/YYYY') : '-',
                    nextDueCancel: siteDoc.data().due_end ? moment(siteDoc.data().due_end*1000).format('ll') : '-',
                    failPay: siteDoc.data().failPay ? siteDoc.data().failPay : false,
                    // nextDueCancel: siteDoc.data().due_end ? moment(siteDoc.data().due*1000).format('MMM DD') : '-',
                    cancelSub: siteDoc.data().cancelSub ? siteDoc.data().cancelSub : false,

                    freeTrial: siteDoc.data().freeTrial ? siteDoc.data().freeTrial : false,
                    customerId: siteDoc.data().customerId ? siteDoc.data().customerId : '',
                    customerIdSite: siteDoc.data().customerId ? siteDoc.data().customerId : '',
                    subscriptionId: siteDoc.data().subscriptionId ? siteDoc.data().subscriptionId : '',
                    planType: siteDoc.data().planType ? parseInt(siteDoc.data().planType, 10) : '',
                    planSite: siteDoc.data().plan ? siteDoc.data().plan : '',
                    paidRecurrency: siteDoc.data().paidRecurrency ? (siteDoc.data().paidRecurrency === 'month' || siteDoc.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : ''
                  })
                  
                  // if(this.$route.name === 'view-errors') {
                    var idLoop = this.rows[0].children.length - 1
                    if(siteDoc.data().owner && siteDoc.data().owner.uid) {
                      // check script exitense
                      if(user.uid === siteDoc.data().owner.uid) {
                        var getFirstUser = user.uid.slice(0, 4);
                        var getFirstSite = siteDoc.id.slice(0, 4);
                        var keyID = getFirstUser + getFirstSite
                        // var idLoop = this.rows[0].children.length
                        // console.log(idLoop)

                        var obj = {
                          "url": siteDoc.data().homepage,
                          "scriptSrc": `https://cdn.seocloud.ai/${keyID}`
                        }

                        var config = {
                          method: 'POST',
                          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          data: JSON.stringify(obj),
                        }

                        this.axios(config)
                        .then(response => {
                          // console.log('header: ',response.data)
                          // console.log(idLoop,response.data.scriptPresent)
                          if(!response.data.scriptPresent) {
                            this.rows[0].children[idLoop].lost = true
                          } else {
                            this.rows[0].children[idLoop].lost = false
                          }
                        })
                      } else {
                        this.rows[0].children[idLoop].lost = false
                      }
                    } else {
                      // history owner lost
                      if(siteDoc.data().owners && siteDoc.data().owners.length) {
                        if(siteDoc.data().owners.includes(user.uid)) {
                          this.rows[0].children[idLoop].lost = true
                        }
                      }
                    }
                  // }
                }
              })

              // horizontal scroll
              this.$nextTick(() => {
                $(".top-scroll-horizontal").scroll(() => {
                  $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal").scrollLeft());
                });

                $(".vgt-responsive").scroll(() => {
                  $(".top-scroll-horizontal").scrollLeft($(".vgt-responsive").scrollLeft());
                });
              });
            })
            
          })
        } else {
          this.$router.push({ name: 'projects' })
        }
      })

      this.loadSuggestSites()
    }
  },
  methods: {
    logIdSite(val) {
      // console.log(val)
      this.idsite = val
      this.showOpen = true
    },
    logIdChangeProject(val) {
      // console.log('first')
      this.idsite = val
      this.showOpenChangeProject = true
    },
    logIdArchive(val) {
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    logIdActive(val) {
      this.idsite = val
      this.showOpenActiveSite = true
    },
    logIdMembersProject(val) {
      // this.idsite = val
      // this.showOpenMembersProject = true
    },
    logIdLicense(val) {
      this.idsite = val
      this.showOpenInfoLicense = true
    },
    loadSuggestSites() {
      const user = auth.currentUser
      if(user) {
        db.collection('Users').doc(user.uid).collection('projects').get()
        .then(userDoc => {
          // console.log(userDoc.size)
          // this.datasuggest.data[0].sites = []
          userDoc.forEach(eleUserProject => {
            db.collection('Projects').doc(eleUserProject.data().id).collection('sites').get()
            .then(queryArchiveProject => {
              queryArchiveProject.forEach(site => {
                if(site.data().status === 'archive') {
                  this.existArchivedSites = true
                }

                // if(site.data().thumbnail) {
                //   function imgExists(url, callback) {
                //     var img = new Image()
                //     img.onerror = function() {
                //       callback(false)
                //     }
                //     img.onload = function () {
                //       callback(true);
                //     }
                //     img.src = url
                //   }

                //   var checkImage = (exists) => {
                //     // console.log(site.name, exists)
                //     this.datasuggest.data[0].sites.push({ id: site.id, img: exists ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b', name: site.data().name ? site.data().name : site.data().WebsiteURL, domainFixed: site.data().WebsiteURL, project: eleUserProject.data().name, status: site.data().status, domain: site.data().homepage, projectid: eleUserProject.data().id, color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)', own: 'me', invitation: '', imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b' })
                //   }

                //   imgExists(site.data().thumbnail, checkImage)
                // } else {
                  // this.datasuggest.data[0].sites.push({ id: site.id, img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b', name: site.data().name ? site.data().name : site.data().WebsiteURL, domainFixed: site.data().WebsiteURL, project: eleUserProject.data().name, status: site.data().status, domain: site.data().homepage, projectid: eleUserProject.data().id, color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)', own: 'me', invitation: '', imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b' })
                // }
              })
              
              // setTimeout(() => {
                this.loaderSearch = false
              //   this.fetchResults(this.searchSite)
              // }, 1500)
            })
          })
        })
      }
    },
    getError(val) {
      this.$router.push({
        name: 'view-errors',
        params: {
          id: val.id, imgSite: val.imgSite, name: val.name, url: val.domain, project: val.projectid, own: this.rows[0].permission === 'owner' ? 'me' : 'other'
        },
      })
    },
    fetchResults(text) {
      const { searchSite } = this
      if (searchSite) {
        this.suggestionsSites = []
      }

      const filteredDevelopers = this.datasuggest.data[0].sites.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.domain.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      filteredDevelopers.length && this.suggestionsSites.push({ data: filteredDevelopers })
      // name: 'sites',
    },
    getSite(val){
      // console.log(val)
      if(val.id) {
        if(val.id && val.own === 'other' && val.invitation.statusInv === 'sended') {
          this.logIdAcceptInvitation(val)
        } else if(val.status === 'archive') {
          this.logIdActive(val)
        } else {
          this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.img, name: val.name, url: val.domain, project: val.projectid, own: val.own} })
        }
      }
    },
    renderSuggestion(suggestion) {
      const site = suggestion.item
      if(this.loaderSearch && this.datasuggest.data[0].sites.length === 0) {
        return (
          <div class="py-50">
            <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
              <b-spinner class="align-middle"></b-spinner>
            </span>
          </div>
        )
      } else {
        if(suggestion.item.id) {
          return (
            <div class="py-50">
              {site.project !== 'My sites' ?
              <span class="mr-50" style="line-height: 0.8rem !important; font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 12px; display: flex; margin-top: 0px;">
                <div style="width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;" class="mr-50"></div>
                <span style="color: #A7AAB9 !important; line-height: 0.8rem !important;">Project</span>
              </span>
              : ''}
              <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                <div style={"width: 12px; height: 12px; border-radius: 4px; background: "+site.color+"; margin-top: 6px;"} class="mr-50"></div>
                <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{site.project.length > 35 ? site.project.substring(0, 35) + '...' : site.project}</span>
              </span>
              <div class="d-flex align-items-center my-50">
                <img src={site.img} alt="" class="mr-50" width="35" height="35" />
                <div class="detail" style="line-height: 0.8rem !important;">
                  <b-card-text class="mb-0" style="font-size: 16px; line-height: 1.2rem !important;">{site.name.length > 55 ? site.name.substring(0, 55) + '...' : site.name}</b-card-text>
                  <b-card-text class="text-muted mb-0" style="font-size: 14px; line-height: 1rem !important;">{site.domainFixed.length > 35 ? site.domainFixed.substring(0, 35) + '...' : site.domainFixed}</b-card-text>
                </div>
              </div>
              { site.status === 'archive' ? 
                <b-button
                  variant="secondary"
                  style="background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 2px 7px; border-radius: initial;"
                  class="p-auto mr-50"
                >
                  <feather-icon
                    icon="ArchiveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle ">Archived</span>
                </b-button>
              : '' }
            </div>
          )
        } else {
          if(this.loaderSearch) {
            return (
              <div class="py-50">
                <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                  <b-spinner class="align-middle"></b-spinner>
                </span>
              </div>
            )
          } else {
            return (
              <div class="py-50">
                <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{'No results'}</span>
                </span>
              </div>
            )
          }
        }
      }
    },
    onRowSelected(items) {
      if(items.column.field !== 'key' && items.column.field !== 'ownedBy' && items.column.field !== 'membership' && items.column.field !== 'action') {
        // console.log(items)
        this.$router.push({
          name: 'view-errors',
          params: {
            id: items.row.id, imgSite: items.row.imgSite, name: items.row.name, url: items.row.domain, project: items.row.projectid, own: this.rows[0].permission === 'owner' ? 'me' : 'other'
          },
        })
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Key copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    getWithScroll() {
      setTimeout(() => {
        const primerElemento = document.querySelector('.table-wproject-sites');
        const segundoElemento = document.getElementById('tab-err-sc2');

        if(primerElemento) {
          const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
          segundoElemento.style.width = ancho + 'px';
        }
      }, 500)
    },
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.getWithScroll)
    })
  },
  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.getWithScroll)
    })
  },
}
</script>

<style>
.table-wproject-sites td {
  padding-top: .1em !important;
  /* padding-right: .1em !important; */
  padding-bottom: .1em !important;
  /* padding-left: .1em !important; */
}
/* .table-show-project .vgt-inner-wrap { */
  /* overflow-x: auto; */
  /* scrollbar-width: thin; */
  /* -ms-overflow-style: none; */
  /* scrollbar-color: #181e38 #d6deff; */
/* } */
.vgt-inner-wrap::-webkit-scrollbar {
  height: 5px;
}
.table-wproject-sites tr:hover {
  background-color: #f6f6f9 !important;
}
.autosuggest-sites .autosuggest__results-container {
  max-width: 350px !important;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  padding-right: 0.75em !important;
}
.tdTableSitesImg {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 120px !important;
  max-width: 120px !important;
  padding-left: 0px !important;
  /* margin: 20px !important; */
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  /* margin: 20px !important; */
}
.thTableSitesNameShow {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  padding-right: 0.75em !important;
  min-width: 160px !important;
  max-width: 360px !important;
  width: 360px !important;
}
.tdTableSitesNameShow {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  min-width: 160px !important;
  max-width: 360px !important;
  width: 360px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
}
.thTableSitesViewListMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  text-align: center !important;
  /* padding-right: 0.75em !important; */
  padding-right: 30px !important;
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px !important;
}
.tdTableSitesViewListMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  padding-right: 30px !important;
  /* margin: 20px !important; */
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px !important;
}
.thTableSitesViewListKey {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  max-width: 112px !important;
  width: 112px !important;
  text-align: center !important;
  padding-right: 0.75em !important;
}
.tdTableSitesKeyViewListKey {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  max-width: 112px !important;
  width: 112px !important;
  /* text-align: center; */
}
.thTableSitesViewListErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  max-width: 130px !important;
  width: 130px !important;
  min-width: 100px !important;
  text-align: center !important;
  padding-right: 0.75em !important;
}
.thTableSitesViewListErrors span {
  margin-right: 10px !important;
}
.tdTableSitesViewListErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  max-width: 130px !important;
  width: 130px !important;
  text-align: center !important;
  /* margin: 20px !important; */
}
.thTableSitesViewList {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  max-width: 145px !important;
  width: 145px !important;
  text-align: center !important;
  padding-right: 0.75em !important;
}
.tdTableSitesViewList {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  max-width: 145px !important;
  width: 145px !important;
  text-align: center !important;
  /* margin: 20px !important; */
}
.tdTableSitesKeyViewList {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  max-width: 132px !important;
  width: 132px !important;
  text-align: center !important;
}
.thTableSitesDomain {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 0.75em !important;
}
.tdTableSitesDomain {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  /* max-width: 425px; */
  min-width: 280px !important;
  word-wrap: break-word;
  vertical-align: middle !important;
  /* margin: 20px !important; */
}
.thTableSitesOwned {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* max-width: 425px; */
  font-weight: 300;
  padding-right: 3px !important;
  padding-left: 3px !important;
  /* margin: 20px !important; */
  width: 107px !important;
  max-width: 107px !important;
  min-width: 107px !important;
}
.tdTableSitesOwned {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 107px !important;
  max-width: 107px !important;
  min-width: 107px !important;
}
.thTableSitesAction {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #DCDCF2 !important; */
  cursor: pointer;
  vertical-align: middle !important;
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
  /* margin: 20px !important; */
}
.tdTableSitesAction {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 16px !important; */
  /* color: #181E38 !important; */
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  padding: 0px !important;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
  padding: 0px !important;
}
.style-chooser .dropdown-toggle {
  background: transparent !important;
  border: 0px !important;
  padding: 0px !important;
  color: #656B85 !important;
}
.style-chooser .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' stroke='' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.style-chooser .dropdown-item {
  padding: 0px 5px;
}
.style-chooser .dropdown-menu {
  border-radius: 10px;
  border: 1px solid #bbbbb9 !important;
  width: 180px !important;
}
.dropdown-sites button {
  padding: 0px !important;
}
.dropdown-sites button:hover {
  background: transparent !important;
}
.autosuggest-sites > div > input {
  width: 325px;
  height: 44px;
  border: 1px solid #A7AAB9;
  border-radius: 22px;
}
.autosuggest-sites.autosuggest__results-container {
  background: #fff !important;
}
/* .table-show-project .vgt-responsive {
  overflow-x: initial !important;
} */
/* .vgt-table.bordered th {
  border: 1px solid #dcdfe6;
} */
/* .vgt-table thead th {
  border-bottom: 1px solid #dcdfe6;
  background: linear-gradient(#f4f5f8,#f1f3f6);
} */
</style>
